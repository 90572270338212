export const environment = {
    logoMultipleColors: '/assets/icons/Attributes.svg',
    logoSingleColor: '/assets/icons/Attributes.svg',
    applicationName: 'Attributes Test Dashboard',
    production: true,
    apiUrl: 'http://attributes_backend.highbiza.nl',
    clientId: '59fafa20189f61ae2059c111_4ovlzteer30gwgggkwsk00g44k88soo8sgwkk40g4kg4kkscow',
    clientSecret: '60h46xgt9yo8ss8skc0owgccsccw04k0scwksc0cooo00g8c4w',
    versionCheckURL: 'http://attributes_dashboard.highbiza.nl/version.json',
    sentryDSN: '',
    sentryEnabled: false,
    environment: 'prod',
    config: {
        companyName: 'ATTRIBUTES',
        showProgressCard: true,
        showWorklogCard: true,
        autoApproveProduction: true,
        showMachineCounter: true,
        supportDefaultEmail: null,
        productionOrderProgressRequiresTypeUpdate: true,
        enablePrepareProductionOrder: true,
        enableQueueProductionOrder: false,
        enableCooperationProductionOrder: true,
        showOrderPrepareStartModal: true,
        favIconName: 'attributes-favicon.png',
        worklogByCalculationNumber: true,
        constantMachine: false,
        filterCnc: false,
        showProductionOrderPdcStatus: "7,8,9,A,B",
    },
};
